import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useMemo, useReducer, useState } from "react";
import { IPrize } from "../common/Types";
import useModal from "../components/custom-hooks/useModalHook";
import { Action, EnumActionKind, listReducer, State } from "../reducers/list-reducer";

export interface IPrizeWheelContext {
  prizes: State;
  setPrizes: Dispatch<Action>;
  isEmptyList: boolean;
  setIsEmptyList: Dispatch<SetStateAction<boolean>>;
  isSpinning: boolean;
  setIsSpinning: Dispatch<SetStateAction<boolean>>;
  handleAdd: (item: IPrize) => void;
  handleDelete: (id: string) => void;
  openWinnerDialog: boolean;
  setOpenWinnerDialog: () => void;
  winningPrize: string;
  setWinningPrize: Dispatch<SetStateAction<string>>;
}

export const defaultState: IPrizeWheelContext = {
  prizes: {
    prizes: [],
  },
  setPrizes: () => null,
  isEmptyList: true,
  setIsEmptyList: () => null,
  isSpinning: false,
  setIsSpinning: () => null,
  handleAdd: () => null,
  handleDelete: () => null,
  openWinnerDialog: false,
  setOpenWinnerDialog: () => null,
  winningPrize: "",
  setWinningPrize: () => null,
};

export const PrizeWheelContext = createContext<IPrizeWheelContext>(defaultState);

export type TPrizeWheelContextProvider = {
  children: ReactNode;
};

export const PrizeWheelContextProvider: FC<TPrizeWheelContextProvider> = ({ children }) => {
  const [isEmptyList, setIsEmptyList] = useState<boolean>(false);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [prizes, setPrizes] = useReducer(listReducer, { prizes: [] });
  const [openWinnerDialog, setOpenWinnerDialog] = useModal();
  const [winningPrize, setWinningPrize] = useState("");

  const handleAdd = (item: IPrize) => {
    setPrizes({ type: EnumActionKind.addPrize, item });
  };

  const handleDelete = (id: string) => {
    setPrizes({ type: EnumActionKind.deletePrize, id });
  };

  const value = useMemo(
    () => ({
      prizes,
      setPrizes,
      isEmptyList,
      setIsEmptyList,
      isSpinning,
      setIsSpinning,
      handleAdd,
      handleDelete,
      openWinnerDialog,
      setOpenWinnerDialog,
      winningPrize,
      setWinningPrize,
    }),
    [prizes, isEmptyList, isSpinning, openWinnerDialog, setOpenWinnerDialog, winningPrize]
  );

  return <PrizeWheelContext.Provider value={value}>{children}</PrizeWheelContext.Provider>;
};
