import React from "react";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import MainPage from "./pages/main-page/main-page";
import ErrorPage from "./pages/error-page/error-page";
import MainLayout from "./templates/main-layout/main-layout";
import LeaderBoardPage from "./pages/leader-board-page/leader-board";
import { PrizeWheelContextProvider } from "./contexts/wheel-of-fortune-context-provider";

const App = () => (
  <div className="App">
    <PrizeWheelContextProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/main" />} />
        <Route path="/main" element={<MainLayout page={<MainPage />} title="Infinity Works - Wheel of fortune" />} />
        <Route
          path="/leaderBoard"
          element={<MainLayout page={<LeaderBoardPage />} title="Infinity Works - Leader board" />}
        />
        <Route path="*" element={<MainLayout page={<ErrorPage />} title="Error Page" />} />
      </Routes>
    </PrizeWheelContextProvider>
  </div>
);

export default App;
