import React, { FC, useContext, useMemo, useState } from "react";
import "./prize-list.scss";
import { v4 as UUID4 } from "uuid";
import { IPrize } from "../../common/Types";
import { PrizeWheelContext } from "../../contexts/wheel-of-fortune-context-provider";

const PrizeList: FC = () => {
  const { prizes, handleAdd, handleDelete, isSpinning } = useContext(PrizeWheelContext);
  const [name, setName] = useState("");
  const [showError, setShowError] = useState(false);

  function onHandleChange(event: React.FormEvent<HTMLInputElement>): void {
    setName(event.currentTarget.value);
  }

  function onHandleAdd(): void {
    if (name !== "" && !isSpinning) {
      handleAdd({ option: name, id: UUID4() });
      setName("");
    } else {
      setShowError(true);
    }
  }

  function onHandleDelete(itemId: string): void {
    if (!isSpinning) {
      handleDelete(itemId);
      setName("");
    } else {
      setShowError(true);
    }
  }

  useMemo(() => {
    if (showError && !isSpinning) {
      setShowError(false);
    }
  }, [showError, isSpinning, setShowError]);

  return (
    <div className="prize-list-container">
      {showError ? <div className="error-message">Cannot amend list while a spin is in progress</div> : null}
      <div className="prize-input-container">
        <label className="custom-field one" htmlFor="prize-input">
          <input
            id="prize-input"
            type="text"
            aria-label="prize-input"
            value={name}
            onChange={onHandleChange}
            placeholder=" "
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onHandleAdd();
              }
            }}
          />
          <span className="placeholder">Prize name...</span>
        </label>
        <button className="prize-button" type="button" onClick={onHandleAdd}>
          Add
        </button>
      </div>
      <div className="list-container">
        <ul className="prize-list">
          {prizes.prizes.map((item: IPrize) => (
            <li className="list-item" key={item.id}>
              <span onClick={() => onHandleDelete(item.id)} role="button" tabIndex={0} aria-hidden="true">
                {item.option}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrizeList;
