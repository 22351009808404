import { useState } from "react";

type TUseModal = [boolean, () => void];

const useModal = (): TUseModal => {
  const [visible, setVisible] = useState(false);
  function toggle() {
    setVisible(!visible);
  }
  return [visible, toggle];
};

export default useModal;
