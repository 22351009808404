import React, { FC } from "react";
import "./footer.scss";
import IWLgo from "../../media/images/InfinityWorks-PartofAccenture-Logo-Horiz-White.svg";

const Footer: FC = () => (
  <footer className="site-footer">
    <img src={IWLgo} alt="Infinity Works - Part of Accenture Logo" />
    <p className="site-footer-title">Copyright © 2022 Infinity Works Company no. 08189469</p>
  </footer>
);

export default Footer;
