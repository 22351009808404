import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FireworksLayout from "./fireworks-layout";
import "./winner-dialog.scss";

type TWinnerDialog = {
  handleCLose: () => void;
  isDialogOpen: boolean;
  title: string;
  message: string;
  prize: string;
};

const WinnerDialog: FC<TWinnerDialog> = ({ handleCLose, isDialogOpen, title, message, prize }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const BrightOrangeColor = "#ff6100";
  const DarkGreyColor = "#222222";

  return isDialogOpen ? (
    <div className="fireworks">
      <FireworksLayout />
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={isDialogOpen}
        onClose={handleCLose}
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            fontSize: "30px",
            borderStyle: "solid",
            borderColor: BrightOrangeColor,
            borderWidth: "thick",
            background: DarkGreyColor,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" classes={{ root: "dialog-title" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="dialog-text">
            {message} <span className="prize-text">{prize}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" classes={{ root: "dialog-button" }} onClick={handleCLose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default WinnerDialog;
