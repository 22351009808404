import React from "react";

// Future versions

const ErrorPage = () => (
  <div>
    <h1>Error page</h1>
  </div>
);

export default ErrorPage;
