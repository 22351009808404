import React from "react";

// Future versions

const LeaderBoardPage = () => (
  <div>
    <h1>Leader Board Page</h1>
  </div>
);

export default LeaderBoardPage;
