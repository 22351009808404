import React, { FC } from "react";
import "./navbar.scss";

type INavBar = {
  title: string;
};

const Navbar: FC<INavBar> = ({ title }) => (
  <header className="nav-bar">
    <div className="nav-bar-container">
      <p className="nav-bar-title">{title}</p>
    </div>
  </header>
);

export default Navbar;
