import { useState, useEffect, useCallback } from "react";

interface IUseAudio {
  url: string;
}

type TUseAudio = [boolean, () => void];

export const useAudio = ({ url }: IUseAudio): TUseAudio => {
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const toggle = useCallback((): void => setIsPlaying((state) => !state), []);

  useEffect(() => {
    if (url) {
      const baseAudio = new Audio(url);
      baseAudio.onended = () => {
        setIsPlaying(false);
      };
      setAudio(baseAudio);
    }
  }, [url]);

  useEffect(() => {
    const playAudio = () => {
      if (audio) {
        try {
          audio.play();
        } catch (err) {
          audio.pause();
        }
      }
    };

    if (isPlaying) {
      playAudio();
    }
  }, [audio, isPlaying]);

  return [isPlaying, toggle];
};
