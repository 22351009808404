import React, { useContext } from "react";
import PrizeList from "../../components/prize-list/prize-list";
import WheelOfFortune from "../../components/wheel-of-fortune/wheel-of-fortune";
import WinnerDialog from "../../components/winner-dialog/winner-dialog";
import { PrizeWheelContext } from "../../contexts/wheel-of-fortune-context-provider";
import "./main-page.scss";

const MainPage = () => {
  const { openWinnerDialog, setOpenWinnerDialog, winningPrize } = useContext(PrizeWheelContext);

  return (
    <div className="main-container">
      {openWinnerDialog ? (
        <WinnerDialog
          handleCLose={setOpenWinnerDialog}
          isDialogOpen={openWinnerDialog}
          title="Winner!"
          message="You have won a"
          prize={winningPrize}
        />
      ) : (
        <>
          <div className="flex-item">
            <WheelOfFortune />
          </div>
          <div className="flex-item">
            <PrizeList />
          </div>
        </>
      )}
    </div>
  );
};
export default MainPage;
