import { IPrize } from "../common/Types";

export enum EnumActionKind {
  addPrize = "ADD_ITEM",
  deletePrize = "DELETE",
  test = "TEST",
}

export type Action =
  | { type: EnumActionKind.addPrize; item: IPrize }
  | { type: EnumActionKind.deletePrize; id: string }
  | { type: EnumActionKind.test };

export type State = {
  prizes: IPrize[];
};

export const listReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        prizes: state.prizes.concat({ option: action.item.option, id: action.item.id }),
      };
    case "DELETE":
      return {
        ...state,
        prizes: state.prizes.filter((item) => item.id !== action.id),
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
