import React, { FC, ReactNode } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";

import "./main-layout.scss";

type IMainLayout = {
  page: ReactNode;
  title: string;
};

const MainLayout: FC<IMainLayout> = ({ page, title }) => (
  <div className="wrapper-main-layout">
    <Navbar title={title} />
    <main className="main-layout">{page}</main>
    <Footer />
  </div>
);

export default MainLayout;
