import React, { useState, FC, useEffect, useContext } from "react";
import { Wheel } from "react-custom-roulette";
import "./wheel-of-fortune.scss";
import SoundEffect from "../../media/sounds/ES-Wheel-Spin.mp3";
import { useAudio } from "../custom-hooks/useSoundHook";
import { PrizeWheelContext } from "../../contexts/wheel-of-fortune-context-provider";

const WheelOfFortune: FC = () => {
  const [playing, setPlaying] = useAudio({ url: SoundEffect });
  const [isSoundEnable, setIsSoundEnable] = useState(true);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const BrightOrangeColor = "#ff6100";
  const DarkOrangeColor = "#cc4e00";
  const BrightWhiteColor = "#ffffff";
  const GreyInfinity = "#42454a";
  const BlackColor = "#000000";

  const { prizes, isEmptyList, setIsEmptyList, setOpenWinnerDialog, isSpinning, setIsSpinning, setWinningPrize } =
    useContext(PrizeWheelContext);

  useEffect(() => {
    if (prizes.prizes.length >= 2) {
      setIsEmptyList(false);
    } else {
      setIsEmptyList(true);
    }
  }, [prizes, setIsEmptyList]);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * prizes.prizes.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
    setIsSpinning(true);
    setWinningPrize("");
    if (isSoundEnable) {
      setPlaying();
    }
  };

  const handleStopSpin = () => {
    const currentPrize = prizes.prizes[prizeNumber];
    setWinningPrize(currentPrize.option);
    setMustSpin(false);
    setIsSpinning(false);
    setOpenWinnerDialog();
  };

  return isEmptyList ? (
    <div className="container">
      <h1>Please add some prizes to get started</h1>
    </div>
  ) : (
    <div className="container">
      <div className="wheel-container">
        <Wheel
          data={prizes.prizes}
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          onStopSpinning={handleStopSpin}
          backgroundColors={[BrightOrangeColor, GreyInfinity, DarkOrangeColor, BlackColor]}
          textColors={[BrightWhiteColor]}
          outerBorderColor={BrightWhiteColor}
          innerBorderWidth={5}
          innerRadius={20}
          innerBorderColor={BrightWhiteColor}
          radiusLineColor={BrightWhiteColor}
          fontSize={12}
        />
      </div>
      <div className="button-container">
        {isSpinning ? null : (
          <div className="spin-button-container">
            <button className="spin-button" onClick={() => handleSpinClick()} id="spin" type="button">
              <span className="shadow" />
              <span className="edge" />
              <span className="front">Spin the wheel</span>
            </button>
          </div>
        )}
        <div
          className="sound-button-container"
          onClick={() => setIsSoundEnable(!isSoundEnable)}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          {isSoundEnable ? (
            <div id="sound-enable" className="sounds" title="sound enable">
              <i className={playing ? "fa-solid fa-volume-high" : "fa-solid fa-volume-off"} />
            </div>
          ) : (
            <div id="sound-disable" className="sounds" title="sound-disable">
              <i className="fa-solid fa-volume-xmark" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WheelOfFortune;
