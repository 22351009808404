import React, { FC } from "react";
import { useLottie, Lottie } from "react-lottie-hook";
import animationObject from "../../media/animations/fireworks.json";
import { useWindowDimensions } from "../custom-hooks/useWindowDimensions";

const FireworksLayout: FC = () => {
  const { width, height } = useWindowDimensions();

  // deep clone animation data object to prevent memory leak
  const animationData = JSON.parse(JSON.stringify(animationObject));

  const [lottieRef] = useLottie({
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      progressiveLoad: false,
    },
    animationData,
  });

  return <Lottie lottieRef={lottieRef} width={width} height={height} />;
};

export default FireworksLayout;
